<template>
  <div
    :key="renderLogin"
    :class="
      isPublic
        ? 'auth-wrapper auth-v2 px-2 login-bg'
        : 'auth-wrapper auth-v1 px-2 login-bg'
    "
    :style="{
      'background-image':
        'url(' + require('@/assets/images/ptsal/bg1compress.jpeg') + ')'
    }"
  >
    <div class="auth-inner py-2">
      <div class="navbar-header d-xl-block" v-if="isPublic">
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <b-link class="navbar-brand" to="/">
              <b-row>
                <b-col>
                  <span class="brand-logo">
                    <b-img :src="appLogoImage" alt="logo" />
                  </span>
                </b-col>
                <b-col>
                  <h2 class="brand-text ml-20">
                    <!-- PT Sarana Abadi Lestari -->
                  </h2>
                </b-col>
              </b-row>
            </b-link>
          </li>
        </ul>
      </div>
      <transition name="slide-fade" mode="slide-fade" v-if="!isPublic">
        <b-card class="mb-0 yo-trans">
          <span>
            <b-button
              variant="outline-primary"
              @click="
                () => {
                  this.isPublic = !this.isPublic
                }
              "
            >
              <feather-icon icon="ArrowLeftCircleIcon" />
              Back
            </b-button>
          </span>

          <b-link class="brand-logo">
            <b-row align-v="center" align-h="between">
              <b-col md="12">
                <b-img :src="appLogoImage" alt="logo" />
                <b-col md="0"></b-col>
              </b-col>
            </b-row>
          </b-link>

          <b-card-title class="mb-1"> Welcome to {{ welcome }} </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start monitoring
          </b-card-text>

          <!-- Error's alert -->
          <b-alert v-if="error" variant="danger" show>
            <div class="alert-body font-small-2">
              <p v-text="error" />
            </div>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10"
            />
          </b-alert>
          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label-for="email" label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <!-- autocomplete="off" -->
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="user@sal.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                  <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                        <small>Forgot Password?</small>
                      </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <!-- autocomplete="off" -->
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit button -->
              <b-button
                v-show="flagAlreadySet === true"
                id="btnHaveFun"
                ref="btnHaveFun"
                variant="primary"
                type="submit"
                @click="funClick()"
                block
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                ⓧ Sign IN ⓧ
              </b-button>
              <b-button
                v-show="flagAlreadySet === false"
                id="btnHaveFunMore"
                variant="primary"
                type="submit"
                :key="keyNormal"
                block
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                Sign IN
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <span> </span>
            <b-link :to="{ name: 'auth-register-v1' }">
              <strong>Create an account</strong>
            </b-link>
          </b-card-text>

          <div class="login-box">
            <form>
              <a href="https://landing.salpalaran.com/" target="_blank">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Visit Company Profile
              </a>
            </form>
          </div>
        </b-card>
      </transition>

      <!-- no auth -->
      <transition name="fade" mode="out-in" v-if="isPublic">
        <div>
          <div class="login-box">
            <b-card class="yo-trans">
              <form class="ml-30">
                <a href="#">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  .
                </a>
                <a href="#">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  .
                </a>
                <a href="#">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  .
                </a>
                <a href="#">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  .
                </a>
                <a href="#" @click.prevent="togglePublicStatus">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <!-- <b-button variant="primary"> -->
                  <feather-icon class="cursor-pointer" icon="KeyIcon" />
                  Login Page
                  <!-- </b-button> -->
                </a>
                <a href="https://landing.salpalaran.com/" target="_blank">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <!-- <b-button variant="primary"> -->
                  <feather-icon class="cursor-pointer" icon="AnchorIcon" />
                  Company Profile
                  <!-- </b-button> -->
                </a>
                <a href="https://visitor.salpalaran.com/" target="_blank">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <!-- <b-button variant="primary"> -->
                  <feather-icon class="cursor-pointer" icon="CalendarIcon" />
                  Ingin Berkunjung Ke SAL ?
                  <!-- </b-button> -->
                </a>
              </form>
            </b-card>
          </div>
          <b-card class="yo-trans">
            <small
              >Total Pengguna :
              <b-badge><feather-icon size="25" icon="UsersIcon" /> 161</b-badge>
              | Total Data :
              <b-badge variant="warning">
                <feather-icon size="25" icon="KeyIcon" />
                <i>authentication as viewer required</i></b-badge
              >
              ( <i>*******</i> )
            </small>
            <b-badge variant="danger">
              <feather-icon icon="HelpCircleIcon" /> HINTS:
            </b-badge>
            "Login to obtain
            <b-badge variant="primary">real-time conditions</b-badge>, or
            <b-badge variant="success">
              <feather-icon size="25" icon="RefreshCcwIcon" /> simply
              refresh</b-badge
            >
            this page to fetch the latest data.".
          </b-card>
          <b-row class="match-height">
            <b-col lg="12" md="12" sm="12" cols="12">
              <!-- realtime check -->
              <b-row class="match-height">
                <b-col lg="6" md="6" cols="12">
                  <statistic-card-with-line-chart
                    class="yo-trans"
                    :key="componentKey.statistic"
                    icon="TruckIcon"
                    color="danger"
                    :statistic="rtTruckTodayALL"
                    statistic-title=""
                    :chart-data="data.statisticsOrder.series"
                  />
                </b-col>
                <b-col lg="2" md="2" cols="12">
                  <statistic-card-vertical
                    class="yo-trans"
                    hide-chart
                    color="danger"
                    icon="TruckIcon"
                    :statistic="rtTruckTodayPatraCount"
                    :loading="rtTruckTodayLoading"
                    statistic-title="PATRA"
                    :disable-view-limit="disableViewLimit"
                    :max-approvement="maxApprovement['PATRA']"
                    :current-approvement="currentApprovement['PATRA']"
                  />
                </b-col>
                <b-col lg="2" md="2" cols="12">
                  <statistic-card-vertical
                    class="yo-trans"
                    hide-chart
                    color="danger"
                    icon="TruckIcon"
                    :statistic="rtTruckTodayPilCount"
                    :loading="rtTruckTodayLoading"
                    statistic-title="PIL"
                    :disable-view-limit="disableViewLimit"
                    :max-approvement="maxApprovement['PIL']"
                    :current-approvement="currentApprovement['PIL']"
                  />
                </b-col>
                <b-col lg="2" md="2" cols="12">
                  <statistic-card-vertical
                    class="yo-trans"
                    hide-chart
                    color="success"
                    icon="TruckIcon"
                    :statistic="rtTruckTodayHuluCount"
                    :loading="rtTruckTodayLoading"
                    statistic-title="HULU"
                    :disable-view-limit="disableViewLimit"
                    :max-approvement="maxApprovement['HULU']"
                    :current-approvement="currentApprovement['HULU']"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-card class="yo-trans">
            <h2>
              <strong>
                <b-badge variant="dark" class="badge-glow button">
                  Kondisi Jetty View
                  <b-badge variant="danger" class="badge-glow button">
                    Today
                  </b-badge>
                  /
                  <b-badge variant="primary" class="badge-glow button">
                    {{ todaySal }}
                  </b-badge>
                </b-badge>
              </strong>
            </h2>
          </b-card>
          <div :class="mode ? 'sal-jetty' : 'sal-jetty-light'">
            <b-row
              class="sal-content row-content justify-content-center match-height"
            >
              <b-col
                md="2"
                class="column"
                v-for="(jettyItem, jettyKey) in jetty"
                :key="jettyKey"
              >
                <b-card class="card-count justify-content-center yo-trans">
                  <h5 class="match-height">{{ jettyKey }}</h5>
                  <hr />
                  <small
                    style="font-size: 14px"
                    class="justify-content-center text-center"
                  >
                    Rencana Sandar:
                    <b-badge
                      variant="primary"
                      v-b-popover.hover.top.html="
                        '🔑 <i>Authentication Required</i>'
                      "
                      :content="content"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      title="🔖 Kapal Rencana Sandar"
                    >
                      {{ jettyItem.rencana_sandar }}
                    </b-badge>
                    <hr />
                    <strong>Sandar : </strong>
                    <b-badge variant="warning">
                      {{ jettyItem.sandar }}
                    </b-badge>
                  </small>
                </b-card>
              </b-col>
            </b-row>

            <b-row
              class="sal-content-sandar row-content justify-content-center"
            >
              <b-col
                md="2"
                class="column"
                v-for="(jettyItem, jettyKey) in jetty"
                :key="jettyKey"
              >
                <b-card
                  v-for="(item, index) in jettyItem?.children"
                  :key="index"
                  class="card-list-item yo-trans"
                >
                  <div class="row-item">
                    <div class="col-item">
                      <div class="card-item mb-0">
                        <b-row>
                          <b-col cols="12">
                            <b-img
                              lazy
                              src="boat2.png"
                              class="congratulation-medal image"
                              height="130"
                              style="opacity: 0.1"
                            />
                          </b-col>
                          <b-col>
                            <small>
                              <b-badge :variant="'info'">
                                <span style="font-size: 9px">
                                  {{ item.pemilik }}
                                </span>
                              </b-badge>
                              <hr />
                              <small
                                v-b-tooltip.hover
                                title="Nomor PKK"
                                class="cursor-pointer"
                              >
                                <span
                                  v-if="!item.show_in"
                                  style="font-size: 9px"
                                  @click="
                                    copyText(
                                      item.no_pkk || item.no_pkk_tug_boat
                                    )
                                  "
                                >
                                  <strong>{{
                                    item.no_pkk || item.no_pkk_tug_boat
                                  }}</strong>
                                </span>
                                <span
                                  v-else
                                  @click="copyText(item.no_pkk_tug_boat)"
                                  style="font-size: 9px"
                                >
                                  <strong>{{ item.no_pkk_tug_boat }}</strong>
                                </span>
                              </small>
                              <hr />
                              <small v-b-tooltip.hover title="Nama Kapal">
                                <span
                                  v-if="!item.show_in"
                                  style="font-size: 14px"
                                >
                                  <strong>{{
                                    item.boat_name || item.name_tug_boat
                                  }}</strong>
                                </span>
                                <span v-else style="font-size: 14px">
                                  <strong>{{ item.name_tug_boat }}</strong>
                                </span>
                              </small>
                              <hr />
                              <span v-b-tooltip.hover title="Kegiatan">
                                <strong style="font-size: 11px">
                                  {{ item.kegiatan }}
                                </strong>
                                <!-- 25 December 2022 - 12:05 PM -->
                              </span>
                              <hr />
                              <span
                                v-if="item.detail_type === 'form_chain_tbbm'"
                              >
                                <b-button
                                  :variant="
                                    summaryPermission(item, 'TBBM')
                                      ? 'outline-danger'
                                      : 'outline-primary'
                                  "
                                  :disabled="summaryPermission(item, 'TBBM')"
                                  block
                                  size="sm"
                                  :to="{
                                    name: 'dashboard-monitoring-rrv-tbbm-summary',
                                    params: {
                                      previous_link: {
                                        name: 'analytic-jalur-sungai-jetty-view'
                                      },
                                      id: item.detail_id,
                                      api: 'form_chain_tbbm'
                                    }
                                  }"
                                >
                                  <feather-icon
                                    class="cursor-pointer"
                                    :icon="
                                      summaryPermission(item, 'TBBM')
                                        ? 'KeyIcon'
                                        : 'ActivityIcon'
                                    "
                                  />
                                </b-button>
                              </span>
                              <span v-if="item.detail_type === 'form_chain_hm'">
                                <b-button
                                  :variant="
                                    summaryPermission(item, 'HM')
                                      ? 'outline-danger'
                                      : 'outline-primary'
                                  "
                                  :disabled="summaryPermission(item, 'HM')"
                                  block
                                  size="sm"
                                  :to="{
                                    name: 'dashboard-monitoring-rrv-hulu-migas-summary',
                                    params: {
                                      previous_link: {
                                        name: 'analytic-jalur-sungai-jetty-view'
                                      },
                                      id: item.detail_id,
                                      api: 'form_chain_hm'
                                    }
                                  }"
                                >
                                  <feather-icon
                                    class="cursor-pointer"
                                    :icon="
                                      summaryPermission(item, 'HM')
                                        ? 'KeyIcon'
                                        : 'ActivityIcon'
                                    "
                                  />
                                </b-button>
                              </span>
                              <span v-if="item.detail_type === 'form_tug_boat'">
                                <b-button
                                  :variant="
                                    summaryPermission(item, 'FTB')
                                      ? 'outline-danger'
                                      : 'outline-primary'
                                  "
                                  :disabled="summaryPermission(item, 'FTB')"
                                  block
                                  size="sm"
                                  :to="{
                                    name: 'dashboard-monitoring-rrv-tug-boat-summary',
                                    params: {
                                      previous_link: {
                                        name: 'analytic-jalur-sungai-jetty-view'
                                      },
                                      id: item.detail_id,
                                      api: ''
                                    }
                                  }"
                                >
                                  <feather-icon
                                    class="cursor-pointer"
                                    :icon="
                                      summaryPermission(item, 'FTB')
                                        ? 'KeyIcon'
                                        : 'ActivityIcon'
                                    "
                                  />
                                </b-button>
                              </span>
                            </small>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </transition>

      <b-progress
        v-if="yo_isLoading"
        animated
        show-progress
        variant="primary"
        :max="100"
        height="1.5rem"
        :striped="true"
        :class="'progress-bar-info'"
      >
        <b-progress-bar
          :value="100"
          :label="`. . .Memuat Data . . .`"
          variant="danger"
        />
      </b-progress>

      <div class="divider my-2">
        <div class="divider-text">
          <span style="color: black; text-decoration: none">
            <small>
              © 2022 - {{ new Date().getFullYear() }}
              <a
                href="https://github.com/yogithesymbian"
                target="_blank"
                rel="noopener noreferrer"
                style="color: black; text-decoration: none"
              >
                Yogi Arif Widodo
              </a>
              🎸
              <a
                href="https://landing.salpalaran.com/"
                target="_blank"
                rel="noopener noreferrer"
                style="color: black; text-decoration: none"
              >
                PT Sarana Abadi Lestari
              </a>
            </small>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'

import VuexyLogo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from 'moment'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    StatisticCardVertical,
    StatisticCardWithLineChart,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage
    }
  },
  data() {
    return {
      todaySal: moment().format('DD MMM yyyy'),
      title: 'PT SAL',
      welcome: 'PT Sarana Abadi Lestari 👋',
      error: '',
      loading: false,
      flagAlreadySet: false,
      keyNormal: 1,
      renderLogin: 1,
      status: '',
      password: '',
      userEmail: '',
      // validation rules
      required,
      email,
      isPublic: true,
      olddata: null,
      data: {
        statisticsOrder: {
          series: [
            {
              name: '( Authentication Required )',
              categories: [
                'Senin, 25 Juli',
                'Selasa',
                'Rabu',
                'Kamis',
                'Jumat',
                'Sabtu'
              ],
              data: [145, 185, 165, 145, 165, 190]
            }
          ]
        },
        statisticsProfit: {
          series: [
            {
              name: 'Total IN OUT',
              categories: [
                'Senin, 25 Juli',
                'Selasa',
                'Rabu',
                'Kamis',
                'Jumat',
                'Sabtu'
              ],
              data: [0, 20, 5, 30, 15, 45]
            }
          ]
        }
      },

      /* jalur darat */
      componentKey: {
        host: 1,
        statistic: 10,
        truck: 20,
        boat: 30,
        qrcode: 40
      },

      groupAccountData: [],
      renderRealtimeTruckSpecific: 1,
      rtTruckTodayPatraCount: [],
      rtTruckTodayTemCount: [],
      rtTruckTodayPilCount: [],
      rtTruckTodayHuluCount: [],
      rtTruckTodayALL: {},
      rtTruckTodayLoading: false,

      disableViewLimit: true,
      maxApprovement: {},
      currentApprovement: {},

      /* jalur sungai data */
      mode: false,
      jetty: {},
      yo_isLoading: false,
      yo_isLoadingValue: 25
    }
  },
  watch: {
    status: async function () {
      console.log('status: ', this.status)
      if (this.status == true) {
        // try to enable
        this.requestNotificationPermission()
      } else {
        // try to disable
      }
    },
    password: async function () {
      const check = await this.$refs.loginForm.validate()
      if (check === false) {
        this.flagAlreadySet = true
        setTimeout(() => {
          let $ref = this.$refs.btnHaveFun
          if ($ref) {
            $ref.addEventListener('mouseover', () => {
              $ref.style.left = `${Math.ceil(Math.random() * 90)}%`
              $ref.style.top = `${Math.ceil(Math.random() * 90)}%`
              $ref.style.position = 'absolute'
              $ref.style.transition = '.5s'
              $ref.style.background = 'color: dodgerblue'
              $ref.style.padding = '10px'
              $ref.style.height = '40px'
              $ref.style.width = '150px'
            })
            $ref.addEventListener('mouseout', () => {
              $ref.style.left = `${Math.ceil(Math.random() * 90)}%`
              $ref.style.top = `${Math.ceil(Math.random() * 90)}%`
              $ref.style.position = 'absolute'
              $ref.style.transition = '.5s'
              $ref.style.background = 'color: dodgerblue'
              $ref.style.padding = '10px'
              $ref.style.height = '40px'
              $ref.style.width = '150px'
            })
          } else {
            // console.log('cant get element lv1')
          }
        }, 0)
      } else {
        this.flagAlreadySet = false
      }
    },
    userEmail: async function () {
      const check = await this.$refs.loginForm.validate()
      if (check === false) {
        this.flagAlreadySet = true
        setTimeout(() => {
          let $ref = this.$refs.btnHaveFun
          if ($ref) {
            $ref.addEventListener('mouseover', () => {
              $ref.style.left = `${Math.ceil(Math.random() * 90)}%`
              $ref.style.top = `${Math.ceil(Math.random() * 90)}%`
              $ref.style.position = 'absolute'
              $ref.style.transition = '.5s'
              $ref.style.background = 'color: dodgerblue'
              $ref.style.padding = '10px'
              $ref.style.height = '40px'
              $ref.style.width = '150px'
            })
            $ref.addEventListener('mouseout', () => {
              $ref.style.left = `${Math.ceil(Math.random() * 90)}%`
              $ref.style.top = `${Math.ceil(Math.random() * 90)}%`
              $ref.style.position = 'absolute'
              $ref.style.transition = '.5s'
              $ref.style.background = 'color: dodgerblue'
              $ref.style.padding = '10px'
              $ref.style.height = '40px'
              $ref.style.width = '150px'
            })
          } else {
            // console.log('cant get element lv1')
          }
        }, 0)
      } else {
        this.flagAlreadySet = false
      }
      return
    }
  },
  computed: {
    summaryPermission() {
      return (row, flag) => {
        return true
      }
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    checkCondition() {
      return this.flagAlreadySet
    },
    userEmailCheck() {
      return this.userEmail
    }
  },
  mounted() {
    this.renderLogin += 2
    document.body.style.zoom = '100%'
  },
  created() {
    this.fetchData()
  },
  methods: {
    changeMode() {
      this.mode = !this.mode
      console.log('clicked')
    },
    async fetchData() {
      this.yo_isLoading = true
      await this.$http
        .get('/v2/semi_realtime_monitoring/user_global', {
          params: {
            from_page: '/analytic/jalur-sungai/jetty-view'
          }
        })
        .then(async (response) => {
          this.yo_isLoading = false
          const { all } = response.data.jalur_darat
          const { jetty_positions, jetty_view } = response.data

          this.rtTruckTodayPatraCount = {
            in_only: 'permission',
            in_out: 'permission'
          }
          this.rtTruckTodayTemCount = {
            in_only: 'permission',
            in_out: 'permission'
          }
          this.rtTruckTodayPilCount = {
            in_only: 'permission',
            in_out: 'permission'
          }
          this.rtTruckTodayHuluCount = {
            in_only: 'permission',
            in_out: 'permission'
          }
          this.rtTruckTodayALL = all

          /* jalur sungai */
          jetty_positions.forEach((element) => {
            const jettyKey = element.position
            if (!this.jetty[jettyKey]) {
              this.$set(this.jetty, jettyKey, {
                sandar: 0,
                rencana_sandar: 0,
                children: [],
                children_rencana: []
              })
            }
          })

          /* jalur sungai item */
          const rencana_sandar = jetty_view.filter(
            (data) => data.flag === 'kapal_rencana_sandar'
          )
          const sandar = jetty_view.filter(
            (data) => data.flag === 'kapal_sandar' && data.posisi != 'null'
          )
          // --------
          // Assuming rencana_sandar and sandar are arrays of objects
          rencana_sandar.forEach((element) => {
            const jettyKey = element.posisi
            try {
              this.jetty[jettyKey].children_rencana = element?.children
            } catch (error) {
              console.log('error:', error)
            }
            this.jetty[jettyKey].rencana_sandar = element?.total_items
          })

          sandar.forEach((element) => {
            const jettyKey = element.posisi
            if (jettyKey === 'JETTY 3A') return

            try {
              this.jetty[jettyKey].children = element?.children
            } catch (error) {
              console.log('error:', error)
            }
            this.jetty[jettyKey].sandar = element.total_items
          })
        })
    },
    togglePublicStatus() {
      this.isPublic = !this.isPublic
      // Add any additional actions you want to perform on click
    },
    requestNotificationPermission() {
      if ('Notification' in window) {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted!')
              // Here you can show a success message or perform any action that requires notifications
            } else if (permission === 'denied') {
              console.log('Notification permission denied.')
              // Here you can show a message asking the user to manually enable notifications in browser settings
            }
          })
          .catch((error) => {
            console.error('Error requesting notification permission:', error)
          })
      } else {
        console.log('Browser does not support notifications.')
      }
    },
    funClick() {
      this.flagAlreadySet = false
      setTimeout(() => {
        this.flagAlreadySet = true
      }, 30)
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true

          useJwt
            .login({
              email: this.userEmail,
              password: this.password
            })
            .then((response) => {
              let { user_data } = response.data

              const role = user_data.roles[0]
              const ability = user_data.abilities

              // Change user's ability to ability (singular) instead of abilities (plural)
              user_data = { ...user_data, ability }
              delete user_data.abilities

              // Change user's role to role (singular) instead of roles (plural)
              user_data = { ...user_data, role: role.name }
              delete user_data.roles

              useJwt.setToken(response.data.access_token)
              useJwt.setRefreshToken(response.data.refresh_token)
              localStorage.setItem('userData', JSON.stringify(user_data))
              this.$ability.update(ability)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .replace(getHomeRouteForLoggedInUser(role.name))
                .then(() => {
                  // const time = moment().format('HH:mm:ss')
                  // let greetTime
                  // if (time >= '05:00:00' && time < '11:00:00') {
                  //   greetTime = 'pagi'
                  // } else if (time >= '11:00:00' && time < '16:00:00') {
                  //   greetTime = 'siang'
                  // } else if (time >= '16:00:00' && time < '19:00:00') {
                  //   greetTime = 'sore'
                  // } else {
                  //   greetTime = 'malam'
                  // }
                  // this.$alert({
                  //   title: `Selamat ${greetTime} ${user_data.full_name}`,
                  //   icon: 'SmileIcon',
                  //   variant: 'primary',
                  //   text: 'Semoga aktivitas anda menyenangkan dan selalu ingat untuk utamakan keselamatan.',
                  // })
                })
                .catch((error) => {
                  // if (!isNavigationFailure(error, NavigationFailureType.redirected)) {
                  Promise.reject(error)
                  this.loading = false
                  // }
                })
                .catch(() => {
                  this.loading = false
                })
            })
            .catch((error) => {
              try {
                this.error = error.response.data.message
              } catch (err) {
                this.error = error
              }
              this.loading = false
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style>
.login-bg {
  background-size: 140%;
  background-position: center center;
  -webkit-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  box-sizing: border-box;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.login-box {
  /* padding: 10px; */
  box-sizing: border-box;

  /* box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6); */
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #032bf4;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #032bf4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 4px;
}

.login-box a:hover {
  background: #032bf4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #032bf4, 0 0 25px #032bf4, 0 0 50px #032bf4,
    0 0 100px #032bf4;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #032bf4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #032bf4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #032bf4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #032bf4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.blurred-box {
  position: relative;
  width: 250px;
  height: 350px;
  top: calc(50% - 175px);
  left: calc(50% - 125px);
  background: inherit;
  border-radius: 2px;
  overflow: hidden;
}

.yo-trans {
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  border-radius: 10px;
  color: #000000;
}

.blurred-box:after {
  content: '';
  width: 300px;
  height: 300px;
  background: inherit;
  position: absolute;
  left: -25px;
  right: 0;
  top: -25px;
  bottom: 0;
  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.05);
  filter: blur(10px);
}
</style>

<style lang="scss" scoped>
.sal-jetty-light {
  transition: all 0.5s;
  .column {
    .card-count {
      position: -webkit-sticky;
      position: sticky;
      top: 8em;
      z-index: 100 !important;
      &:hover {
        transition: all 0.3s;
        scale: 1.2;
      }
    }
    .card-list-item {
      hr {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
      }
      &:hover {
        transition: all 0.8s;
        scale: 1.2;
        .card-item {
          z-index: 99 !important;
          .image {
            animation-name: boat;
            animation-duration: 1.5s;
            @keyframes boat {
              0% {
                transform: rotate(40deg);
              }
              25% {
                transform: rotate(95deg);
              }
              50% {
                transform: rotate(-40deg);
              }
            }
            /*scale: 0.2;*/
          }
        }
      }
      .row-item {
        .col-item {
          .card-item {
            .image {
              transition: all 0.5s;
            }
          }
        }
      }
    }
  }
}
.sal-jetty {
  transition: all 0.5s;
  .row-content {
  }
  .sal-title {
    /*transform: matrix(4, 1, -1, 4, 80, 80);
    scale: 0.11;
    position: absolute;
    right: 0;
    width: 100%;*/
  }
  .sal-content {
    margin-left: 18rem;
  }
  .sal-content-sandar {
    margin-top: -15rem;
  }

  .column {
    transform: matrix(3, 1, -2, 2, 80, 80);
    scale: 0.2;
    box-shadow: 10px 10px 5px lightblue;
    transition: all 0.5s;
    &:hover {
      transform: matrix(4, 0, 0, 3, 80, 80);
      scale: 0.25;
      z-index: 100;
      box-shadow: 0px 0px 0px lightblue;
      .card-list-item {
        .row-item {
          .col-item {
            .card-item {
              .image {
                animation-name: boat;
                animation-duration: 1.5s;
                @keyframes boat {
                  0% {
                    transform: rotate(40deg);
                  }
                  25% {
                    transform: rotate(95deg);
                  }
                  50% {
                    transform: rotate(-40deg);
                  }
                }
                /*scale: 0.2;*/
              }
              .divider {
                animation-name: divider;
                animation-duration: 1s;
              }
              @keyframes divider {
                0% {
                  margin-bottom: 0em;
                }
                25% {
                  margin-bottom: -20em;
                }
                50% {
                  margin-bottom: 0em;
                }
              }
            }
          }
        }
      }
    }
    .card-count {
      position: -webkit-sticky;
      position: sticky;
      top: 2em;
      z-index: 100 !important;
      animation-name: count;
      animation-duration: 1s;
      @keyframes count {
        0% {
          scale: 0;
        }
        25% {
          scale: 1;
        }
        50% {
          scale: 0;
        }
      }
    }
    .card-list-item {
      .row-item {
        .col-item {
          .card-item {
            .divider {
              /*
              transform: matrix(4, -2, 6, 8, 80, 80);
              scale: 0.2;
              */
              transition: all 0.5s;
            }
          }
        }
      }
    }
  }
}
</style>
